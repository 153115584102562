import React from "react";
import classNames from "classnames";
import { Person } from "@molecules";
import { Text, Container } from "@atoms";

const PeopleGrid = ({ bgColor, copy, people, className: _className }) => {
  return (
    <section
      className={classNames(
        "py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
          "bg-white": bgColor === "white",
        },
        _className
      )}
    >
      <Container>
        <div className="flex flex-col items-center">
          {copy && (
            <div className="mx-auto max-w-3xl">
              <Text
                richText
                className={classNames(
                  "text-center prose-p:text-lg lg:prose-p:text-xl",
                  {
                    "prose-dark": bgColor === "midnight",
                  }
                )}
              >
                {copy}
              </Text>
            </div>
          )}
          <div className="mt-12">
            <div className="flex flex-wrap justify-center flex-gap-8">
              {people?.map(p => {
                return (
                  <Person
                    {...p}
                    key={p.uid}
                    bgColor={bgColor}
                    className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

PeopleGrid.defaultProps = {};

export default PeopleGrid;
